// import Home from "../views/Home";

const titleAfter = ` | R4U World - Interaktívna mapa realít`

export const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        // component: Home,
        meta: {
            footer: true,
            navbar: true,
            publicRoute: true,
            title: `Interaktívna mapa realít | R4U World`,
            metaTags: [
                {
                    name: 'description',
                    content: 'Realitná platforma R4U.app s modernými funkciami. Prináša nové príležitosti prehľadávania nehnuteľností s interaktívnou mapou, ktorá inovuje realitný trh.'
                },
                {
                    property: 'og:title',
                    content: 'Interaktívna mapa realít | R4U World'
                },
                {
                    property: 'og:description',
                    content: 'Realitná platforma R4U.app s modernými funkciami. Prináša nové príležitosti prehľadávania nehnuteľností s interaktívnou mapou, ktorá inovuje realitný trh.'
                },
                {
                    property: 'og:url',
                    content: 'https://r4u.app/'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:site_name',
                    content: 'R4U.app'
                },
                {
                    property: 'og:image',
                    content: 'https://r4u.app/img/R4Uofficiallogo.webp'
                },
                {
                    name: 'facebook-domain-verification',
                    content: 'fzv6ahnh0gs65hl8i5kog6mo43y813'
                },
            ],

            sitemap: {
                priority:   1.0,
            }
        }
    },
    {
        path: '/mapa',
        name: 'Map',
        component: () => import('../views/Map.vue'),
        meta: {
            footer: false,
            navbar: true,
            publicRoute: true,
            title: `Mapa | R4U World - Interaktívna mapa realít`,
            metaTags: [
                {
                    name: 'description',
                    content: 'Naša plaforma je sprístupnená pre fyzické a právnické osoby, no tak isto aj maklérov a realitné spoločnosti. Pomôže pri hľadaní či predaji nehnuteľnosti.'
                },
                {
                    property: 'og:title',
                    content: 'Mapa | R4U World - Interaktívna mapa realít'
                },
                {
                    property: 'og:description',
                    content: 'Naša plaforma je sprístupnená pre fyzické a právnické osoby, no tak isto aj maklérov a realitné spoločnosti. Pomôže pri hľadaní či predaji nehnuteľnosti.'
                },
                {
                    property: 'og:url',
                    content: 'https://r4u.app/mapa'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:site_name',
                    content: 'R4U.app'
                },
                {
                    property: 'og:image',
                    content: 'https://r4u.app/img/R4Uofficiallogo.webp'
                },
            ],
            sitemap: {
                priority: 0.7,
            }
        }
    },
    {
        path: '/mapa/:id',
        name: 'SharedOffer',
        component: () => import('../views/Admin/SharedOffer.vue'),
        meta: {footer: false, navbar: false, title: `Mapa` + titleAfter, sitemap: {ignoreRoute: true}}
    },
    {
        path: '/ponuka/:offerId',
        name: 'OfferDetailPage',
        component: () => import('../views/Pages/OfferDetailPage.vue'),
        meta: { footer: true, navbar: true, title: `Ponuka` + titleAfter, sitemap: { ignoreRoute: true } }
    },
    {
        path: '/ponuka/edit/:offerId',
        name: 'EditOffer',
        component: () => import('../views/Admin/EditOffer.vue'),
        meta: { requiresAuth: true, footer: false, navbar: true, title: `Edit` + titleAfter, sitemap: { ignoreRoute: true } }
    },
    {
        path: '/prihlasenie',
        name: 'Login',
        component: () => import('../views/Auth/Login.vue'),
        meta: { requiresUnauth: true, footer: false, navbar: true, title: `Prihlásenie` + titleAfter, sitemap: { ignoreRoute: true } }
    },
    {
        path: '/registracia',
        name: 'Registration',
        component: () => import('../views/Auth/Registration.vue'),
        meta: { requiresUnauth: true, footer: false, navbar: true, title: `Registrácia` + titleAfter, sitemap: { ignoreRoute: true } }
    },
    {
        path: '/oblubene',
        name: 'FavoriteOffers',
        component: () => import('../views/Admin/FavoriteOffers.vue'),
        meta: { requiresAuth: true, footer: false, navbar: true, title: `Obľúbené` + titleAfter, sitemap: { ignoreRoute: true } }
    },
    {
        path: '/ucet',
        name: 'Account',
        component: () => import('../views/Admin/Account.vue'),
        meta: { requiresAuth: true, footer: false, navbar: true, title: `Účet` + titleAfter, sitemap: { ignoreRoute: true } }
    },
    {
        path: '/moje-ponuky',
        name: 'MyOffers',
        component: () => import('../views/Admin/MyOffers.vue'),
        meta: { requiresAuth: true, footer: false, navbar: true, title: `Moje ponuky` + titleAfter, sitemap: { ignoreRoute: true } }
    },
    {
        path: '/nova-ponuka',
        name: 'CreateOffer',
        component: () => import('../views/Admin/CreateOffer.vue'),
        meta: { requiresAuth: true, footer: false, navbar: true, title: `Nová ponuka` + titleAfter, sitemap: { ignoreRoute: true } }
    },
    {
        path: '/o-nas',
        name: 'About',
        component: () => import('../views/Pages/About.vue'),
        meta: {
            requiresAuth: false,
            footer: true,
            navbar: true,
            title: `O nás | R4U World - Interaktívna mapa realít`,
            metaTags: [
                {
                    name: 'description',
                    content: 'Pomocou tejto webovej aplikácie Vám ponúkame vyhľadávanie, sprostredkovanie, ' +
                        'predaj a prenájom nehnuteľnosti v dynamickom prostredí s interaktívnymi funkciami.'
                },
                {
                    property: 'og:title',
                    content: 'O nás | R4U World - Interaktívna mapa realít'
                },
                {
                    property: 'og:description',
                    content: 'Pomocou tejto webovej aplikácie Vám ponúkame vyhľadávanie, sprostredkovanie, ' +
                        'predaj a prenájom nehnuteľnosti v dynamickom prostredí s interaktívnymi funkciami.'
                },
                {
                    property: 'og:url',
                    content: 'https://r4u.app/o-nas'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:site_name',
                    content: 'R4U.app'
                },
                {
                    property: 'og:image',
                    content: 'https://r4u.app/img/R4Uofficiallogo.webp'
                },
            ],
            sitemap: {priority: 0.7,}
        }
    },
    {
        path: '/dakujeme',
        name: 'ThankYou',
        component: () => import('../views/Pages/ThankYou.vue'),
        meta: { requiresAuth: false, footer: true, navbar: true, title: `Ďakujeme` + titleAfter, sitemap: { ignoreRoute: true } }
    },
    {
        path: '/ochrana-osobnych-udajov',
        name: 'Gdpr',
        component: () => import('../views/Pages/Gdpr.vue'),
        meta: { requiresAuth: false, footer: true, navbar: true, title: `Ochrana osobných údajov` + titleAfter, sitemap: {priority: 0.5,} }
    },
    {
        path: '/obchodne-podmienky',
        name: 'CommercialTerms',
        component: () => import('../views/Pages/CommercialTerms.vue'),
        meta: { requiresAuth: false, footer: true, navbar: true, title: `Obchodné podmienky` + titleAfter, sitemap: {priority: 0.5,} }
    },
    {
        path: '/cennik',
        name: 'Pricing',
        component: () => import('../views/Pages/Pricing.vue'),
        meta: {
            requiresAuth: false,
            footer: true,
            navbar: true,
            title: `Cenník` + titleAfter,
            metaTags: [
                {
                    name: 'description',
                    content: 'Platba za jeden inzerát platí 30 dní. Ponúkame automatické platby pomocou platobnej karty. ' +
                        'Platobné možnosti ako platba kartou, či pomocou SMS správy.'
                },
                {
                    property: 'og:title',
                    content: `Cenník` + titleAfter,
                },
                {
                    property: 'og:description',
                    content: 'Platba za jeden inzerát platí 30 dní. Ponúkame automatické platby pomocou platobnej karty. ' +
                        'Platobné možnosti ako platba kartou, či pomocou SMS správy.'
                },
                {
                    property: 'og:url',
                    content: 'https://r4u.app/cennik'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:site_name',
                    content: 'R4U.app'
                },
                {
                    property: 'og:image',
                    content: 'https://r4u.app/img/R4Uofficiallogo.webp'
                },
            ],
            sitemap: {priority: 0.7,} }
    },
    {
        path: '/reset-password/:token',
        name: 'PasswordReset',
        component: () => import('../views/Auth/PasswordReset.vue'),
        meta: { requiresAuth: false, footer: false, navbar: true, title: `Obnova hesla` + titleAfter, sitemap: { ignoreRoute: true } }
    },
    {
        path: '/prihlasenie/:token',
        name: 'ExternalLogin',
        component: () => import('../views/Auth/ExternalLogin.vue'),
        meta: { requiresAuth: false, footer: false, navbar: false, title: `Prihlásenie` + titleAfter, sitemap: { ignoreRoute: true } }
    },
    {
        path: '/admin',
        name: 'Admin',
        component: () => import('../views/CMS/Admin.vue'),
        meta: { requiresAdminAuth: true, footer: false, navbar: true, title: `Admin` + titleAfter, sitemap: { ignoreRoute: true } }
    },
    {
        path: '/admin/import',
        name: 'AdminFeedsImport',
        component: () => import('../views/CMS/ImportFeeds.vue'),
        meta: { requiresAdminAuth: true, footer: false, navbar: true, title: `Admin` + titleAfter, sitemap: { ignoreRoute: true } }
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import('../views/Pages/404.vue'),
        meta: { footer: false, navbar: true, title: `404` + titleAfter, sitemap: { ignoreRoute: true } }
    }
    // {
    //   path: '/admin/prihlasenie',
    //   name: 'AdminLogin',
    //   component: () => import('../views/CMS/AdminLogin.vue'),
    //   meta: { footer: false, navbar: true }
    // }
]
