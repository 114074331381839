<template>
  <div id="confirm"></div>
  <navbar v-if="$route.meta.navbar"></navbar>
  <div class="page">
    <router-view/>
  </div>
  <div v-if="$route.meta.footer">
    <Footer/>
  </div>
  <Compliance></Compliance>
</template>

<script>
// @ is an alias to /src
// Bootstrap JS
import "bootstrap/dist/js/bootstrap.min.js";
import {mapGetters} from 'vuex';

// Components import
import Navbar from '@/components/Layout/Navbar.vue'
import Footer from '@/components/Layout/Footer.vue'
import Compliance from '@/components/Layout/Compliance.vue'

export default {
  name: 'App',
  data() {
    return {

    };
  },
  components: {
    Navbar,
    Footer,
    Compliance
  },
  computed: {
    ...mapGetters('auth', ['getUserAuthState', 'getUser', 'isAuthenticated']),
  },
  methods: {
  },
  mounted() {
    this.$store.dispatch('auth/autoLogin');
    this.$store.dispatch('auth/checkCompliance');
    // this.$store.dispatch('auth/checkUserDataIntegrity');
  }
}
</script>

<style>

/*!* Bootstrap CSS *!*/
@import "./assets/scss/main.css";

body {
  overflow: auto !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

</style>
