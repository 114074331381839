<template>
  <div class="compliance-container bg-black" v-show="!accepted">
    <div class="container">
      <div class="row">
        <div class="col-12 py-4 mb-sm-0" :class="{'mb-16': currentPage === 'Home'}">
          <p class="my-2 text-center">Používame cookies, aby ste mali lepší zážitok z našej stránky. Môžete sa dozvedieť viac o cookies ktoré používame na stránke
            <router-link to="/ochrana-osobnych-udajov" class="btn btn-link p-0">GDPR</router-link></p>
          <div class="text-center">
            <button class="btn btn-primary" @click="acceptCompliance()">Rozumiem</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapGetters} from 'vuex';

export default {
  name: 'Compliance',
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('auth', ['getCompliance']),
    accepted() {
      return this.getCompliance
    },
    currentPage() {
      return this.$route.name;
    }
  },
  methods: {
    acceptCompliance() {
      this.$store.dispatch('auth/acceptCompliance');
    }
  },
}
</script>

<style scoped>
.compliance-container {
  z-index: 9999;
  display: inline-block;
  width: 100vw;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
