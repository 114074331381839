<template>
  <footer class="footer bg-dark text-white mt-5">
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-3 col-12">
          <div class="brand"><img src="~@/assets/img/front/R4Uofficiallogo.webp" height="55" alt="Logo"></div>
          <div class="text-left mt-4">R4U je skupina odborníkov, ktorá sa pohybuje v realitnej sfére viac ako štyri desaťročia.
            Po niekoľkých rokoch sledovania realitného trhu sme sa rozhodli zlepšiť a posunúť realitné služby na novú
            úroveň.
          </div>
        </div>
        <div class="col-lg-2 offset-lg-1 col-12 pt-4 pt-lg-0">
          <h6>Menu</h6>
          <ul class="list-unstyled">
            <li class="pb-2">
              <router-link to="/" class="ps-0">Domov</router-link>
            </li>
            <li class="pb-2">
              <router-link to="/mapa" class="ps-0">Mapa</router-link>
            </li>
            <li class="pb-2">
              <router-link to="/o-nas" class="ps-0">O nás</router-link>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-12">
          <h6>Informácie</h6>
          <ul class="list-unstyled">
            <li class="pb-2">
              <router-link to="/cennik" class="ps-0">Cenník</router-link>
            </li>
            <li class="pb-2">
              <router-link to="/obchodne-podmienky" class="ps-0">Obchodné podmienky</router-link>
            </li>
            <li class="pb-2">
              <router-link to="/ochrana-osobnych-udajov" class="ps-0">Ochrana osobných údajov</router-link>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-12">
          <div class="">
            <h6>Kontaktujte nás:</h6>
            <div class="mt-3">
              <div class="d-flex">
                <p><img src="~@/assets/img/front/icons/call_white_24dp.svg" height="20" width="20" alt="" class="me-2"> <a href="tel:+421 905 223 527">+421 905 223 527</a></p>
              </div>
              <div class="d-flex">
                <p><img src="~@/assets/img/front/icons/email_white_24dp.svg" height="20" width="20" alt="" class="me-2"> <a href="mailto:info@r4u.world">info@r4u.world</a></p>
              </div>
            </div>
          </div>
          <div class="mt-4" id="footerSocial">
            <h6>Nájdete nás na:</h6>
            <div class="d-inline-flex align-items-center mt-2">
              <a href="https://www.facebook.com/R4Uapp-Komplexná-mapa-realít-109982278136261/" class="social-icon me-3" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon-fb" width="32" height="32"
                     viewBox="0 0 27.57 27.404">
                  <path id="Icon_awesome-facebook" data-name="Icon awesome-facebook"
                        d="M28.133,14.348A13.785,13.785,0,1,0,12.194,27.966V18.333h-3.5V14.348h3.5V11.31c0-3.455,2.057-5.363,5.207-5.363a21.215,21.215,0,0,1,3.086.269V9.607H18.748A1.992,1.992,0,0,0,16.5,11.76v2.587h3.823l-.611,3.985H16.5v9.633A13.79,13.79,0,0,0,28.133,14.348Z"
                        transform="translate(-0.563 -0.563)" fill="#fff"/>
                </svg>
              </a>
              <a href="https://instagram.com/r4u.app" class="social-icon me-3" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon-ig" width="32" height="32" viewBox="0 0 27.242 27.242">
                  <g id="Icon_feather-instagram" data-name="Icon feather-instagram" transform="translate(-1.5 -1.5)">
                    <path id="Path_3235" data-name="Path 3235"
                          d="M9.061,3H21.182a6.061,6.061,0,0,1,6.061,6.061V21.182a6.061,6.061,0,0,1-6.061,6.061H9.061A6.061,6.061,0,0,1,3,21.182V9.061A6.061,6.061,0,0,1,9.061,3Z"
                          transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="3"/>
                    <path id="Path_3236" data-name="Path 3236"
                          d="M21.709,16.072a4.848,4.848,0,1,1-4.085-4.085A4.848,4.848,0,0,1,21.709,16.072Z"
                          transform="translate(-1.74 -1.715)" fill="none" stroke="#fff" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="3"/>
                    <path id="Path_3237" data-name="Path 3237" d="M26.25,9.75h0" transform="translate(-4.462 -1.296)"
                          fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  </g>
                </svg>
              </a>
              <a href="https://www.youtube.com/channel/UCL9m5KxUt5nXDl3954nv7bA" class="social-icon" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" height="42" viewBox="0 0 24 24" fill="none" stroke="#ffffff"
                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-youtube">
                  <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path>
                  <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright col text-center py-2"><p class="mb-0">© {{copyDate}} | Tím <a href="https://onlinesluzby.sk" target="_blank">Online Služby</a></p></div>
  </footer>
</template>

<script>
// @ is an alias to /src
import {mapGetters} from 'vuex';

export default {
  name: 'Footer',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('auth', ['getUserAuthState', 'getUser', 'isAuthenticated']),
    copyDate() {
      let newDate =  new Date();
      return newDate.getFullYear();
    }
  },
  methods: {},
}
</script>

<style scoped>
.copyright {
  background-color: rgba(51, 52, 54, 0.4) !important;
}

.footer a:hover {
  text-decoration: none
}
</style>
