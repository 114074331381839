<template>
    <nav id="nav" class="navbar navbar-expand-lg navbar-dark" :class="{ 'have-hero':haveHero() }">
      <div class="container py-2 py-lg-0">
        <router-link class="navbar-brand d-flex" to="/"><img src="~@/assets/img/front/R4Uofficiallogo.webp" height="55" alt="Logo"></router-link>
        <button class="navbar-toggler border-0 shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse mt-3 mt-lg-0" id="navbarContent">
          <ul class="navbar-nav text-uppercase ma-auto mb-2 ms-lg-5 mb-lg-0">
            <li class="nav-item">
              <router-link to="/" class="nav-link">Domov</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/o-nas" class="nav-link">O nás</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/mapa" class="nav-link">Mapa</router-link>
            </li>
          </ul>
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0" v-if="!isLoggedIn">
            <li class="nav-item ps-lg-3">
              <router-link to="/prihlasenie" class="btn btn-link pt-3 ps-0 p-lg-2 fw-bold">Prihlásiť sa</router-link>
            </li>
            <li class="nav-item ps-lg-3">
              <router-link to="/prihlasenie" class="btn btn-primary">Vytvoriť ponuku</router-link>
            </li>
          </ul>
          <ul class="navbar-nav align-items-lg-center align-items-start ms-auto mb-2 mb-lg-0 pt-2 pt-lg-0 mb-lg-0" v-else>
            <li class="nav-item ps-lg-3">
              <router-link to="/nova-ponuka" class="btn btn-primary">Vytvoriť ponuku</router-link>
            </li>
            <li class="nav-item ps-lg-3 pt-3 pt-lg-0 align-self-auto align-self-lg-center pb-0">
              <router-link to="/oblubene" class="btn btn-link btn-user-dropdown px-0 px-lg-3" id="profileFavoriteOffers">
                <img src="~@/assets/img/front/icons/feather-heart.svg" height="30" alt="">
                <span class="d-inline d-lg-none ms-3 text-white fw-bold">Obľúbené</span>
              </router-link>
            </li>
            <span v-if="formController.isLoading" class="float-start">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgba(255, 255, 255, 0); display: block; shape-rendering: auto;" width="32" height="32" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" fill="none" stroke="#fab702" stroke-width="14" r="38" stroke-dasharray="179.0707812546182 61.690260418206066">
                  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.970873786407767s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                </circle>
                  <!-- [ldio] generated by https://loading.io/ -->
              </svg>
              </span>
            <div class="dropdown nav-item" v-else>
              <button class="btn btn-link btn-user-dropdown px-0 px-lg-3" type="button" id="profileDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="~@/assets/img/front/icons/account_circle_white_24dp.svg" height="40" alt="">
                <span class="d-inline d-lg-none ms-3 text-white fw-bold">Môj účet</span>
              </button>
              <ul class="dropdown-menu dropdown-menu-dark dropdown-user-menu" aria-labelledby="profileDropdownMenu">
                <li><router-link to="/moje-ponuky" class="dropdown-item">Moje ponuky</router-link></li>
                <li><router-link to="/ucet" class="dropdown-item">Moj účet</router-link></li>
                <li>
                  <button class="dropdown-item" :disabled="formController.isLoading" v-on:click="logout">
                    <img src="~@/assets/img/front/icons/feather-log-out.svg" height="20" alt="" class="log-out-icon">
                    <span class="pt-1">{{ submitButtonContent }}</span>
                  </button>
                </li>
              </ul>
            </div>
          </ul>
        </div>
      </div>
    </nav>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'Navbar',
  components: {

  },
  watch: {
    '$route' () {
      if (this.isNavbarOpen) {
        document.querySelector("#navbarContent").classList.remove("show");
      }
      this.isNavbarOpen = true;
    }
  },
  data() {
    return {
      formController: {
        error: null,
        isLoading: false,
      },
      isNavbarOpen: true,
    };
  },
  methods: {
    haveHero() {
      if(this.$route.name === 'Home' || this.$route.name === 'Map') {
        return true;
      }else {
        return false;
      }
    },
    async logout() {
      if (this.getUserAuthState) {
        this.formController.isLoading = true;
        this.$store.dispatch('auth/logout').then(() => {
          // this.$router.go()
          this.$toast.success(`Boli ste úspešne odhlásený !`);
        })
        this.formController.isLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getUserAuthState', 'getUser', 'isAuthenticated']),
    isLoggedIn() {
      return this.getUserAuthState;
    },
    submitButtonContent() {
      if (this.formController.isLoading) {
        return 'Odhlasujem ...';
      } else {
        return 'Odhlásiť sa';
      }
    }
  }
}
</script>

<style>
.navbar {
  min-height: 76px;
}

.btn-link:focus {
  box-shadow: none !important;
}

.dropdown-user-menu {
  right: 0;
  left: auto !important;
}

.dropdown.nav-item{
  width: auto;
}

/*.btn-user-dropdown {*/
/*  text-transform: none !important;*/
/*}*/

.btn-user-dropdown:hover{
  text-decoration: none !important;
}

.btn-user-dropdown span{
  font-size: 14px;
}

@media (max-width: 992px) {
  .dropdown.nav-item{
    width: 100%;
  }
}

.log-out-icon {
  margin-top: -2px;
  margin-right: 10px;
}

</style>
