import {createApp} from 'vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import vueDebounce from 'vue-debounce'
import Toaster from '@meforma/vue-toaster';
import VueConfirmPlugin from 'v3confirm'
import Tooltip from "vue3-popper";
import print from 'vue3-print-nb';
import Maska from 'maska';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App.vue';
import store from './store/index.js';

// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

import { createHead } from '@vueuse/head'

import './registerServiceWorker.js'

const head = createHead()

const app = createApp(App);

app.config.globalProperties.$filters = {
    currencyEuro(value) {
        if (value !== null && value !== undefined) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' €';
        } else {
            return value
        }
        // $filters.currencyEuro()
    },
    areaSquare(value) {
        if (value !== null && value !== undefined) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' \u33A1';
        } else {
            return value
        }
        // $filters.areaCubic()
    },
    date(value) {
        if (value !== null) {
            // return value.toLocaleDateString('sk-SK');
            return new Date(value).toLocaleDateString('sk-SK').replaceAll(" ", "");
        } else {
            return value
        }
        // $filters.date()
    }
}

app.use(router, VueAxios, axios);
app.use(vueDebounce);
app.use(store);
app.use(head);
app.use(Maska);
app.component("Tooltip", Tooltip);
app.use(Toaster, {
    // One of the options
    position: 'top-right'
})
app.use(VueConfirmPlugin, {
    root: '#confirm',
    yesText: 'Áno',
    noText: 'Nie',
})
app.use(print);
// app.use(require('prerender-node').set('prerenderToken', 'TbBpfNS7eChco9LQ5R4A'));

// Sentry.init({
//     app,
//     dsn: "https://c531f1e1f1da4f619534dd598ce5ed0e@o1088797.ingest.sentry.io/6103599",
//     integrations: [
//         new Integrations.BrowserTracing({
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//             tracingOrigins: ["localhost", "r4u.app", /^\//],
//         }),
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });


app.mount('#app');
